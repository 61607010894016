import React from 'react';
import { Input, Checkbox } from 'antd';

const TopSearchBar = (props) => {

  return (
    <div className='top-search-bar-container'>
      <div className='top-bar-menu-item-box orang' style={{ position: 'relative' }}>
        <i
          className='ri-search-line'
          style={{ color: '#9f9f9f', position: 'absolute', top: 22, right: 50, zIndex: '7' }}
        ></i>

        <Input className='search-input'
          onChange={(e) => props.setFilter(e.target.value)}
          placeholder='UPIŠITE NAZIV, DEO NAZIVA ILI ŠIFRU ARTIKLA'
          style={{ width: '500px', paddingLeft: '40px' }}
        />
      </div>
      <div className='top-bar-menu-item-box orange'>
        <Checkbox />
        BRZI MENI
        <i className='ri-information-fill' style={{ marginLeft: '10px' }}></i>
      </div>
      <div className='top-bar-menu-item-box orange'>
        <Checkbox checked={props.outletValue} onChange={({ target }) => {
          props.setIsModalOpen(!props.isModalOpen);
          props.setOutletValue(!props.outletValue);
          if (target.checked === false) {
            props.setIsModalOpen(false);
          }
        }} />
        OUTLET
        <i className='ri-information-fill' style={{ marginLeft: '10px' }}></i>
      </div>
      <div className='top-bar-menu-item-box orange' style={{ cursor: 'pointer' }}>
        KAKO SE PORUČUJE <i className='ri-information-fill' style={{ marginLeft: '10px' }}></i>
      </div>
    </div>
  );
};

export default TopSearchBar;
