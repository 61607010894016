import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { ROUTES } from '../../config';
import { UserContext } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
  const { isCollapsed, collapsedNav } = props;
  const currentuser = useContext(UserContext);

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('amounts');
    sessionStorage.removeItem('orderedProducts');
    sessionStorage.removeItem('totalPrice');
    window.location.href = '/company';
  };

  return (
    <Sider
      breakpoint='lg'
      className={collapsedNav ? 'nav-menu-collapsed' : 'nav-display'}
      trigger={null}
      collapsible
      collapsed={isCollapsed}
      style={{ zIndex: 6, overflowX: 'hidden' }}
    >
      <Link to='/company' className='logo-desktop'>
        <div className='logo'>
          <img src='/himtex-logo.svg' style={{ maxHeight: '49px', width: '70%' }} alt='Universal api' />
        </div>
      </Link>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[window.location.pathname]}>
        {currentuser.data &&
          currentuser.data.role &&
          ROUTES.filter((route) => route.showInMenu.some((item) => currentuser.data.role.includes(item))).map(
            (route) => (
              <SubMenu
                className='submenu'
                key={route.label.toLowerCase()}
                title={
                  isCollapsed ? (
                    <route.icon />
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <i className={`navMenuIcon ${route.icon}`}></i>
                      <Link to={route.children[0].path}>{route.label}</Link>
                    </span>
                  )
                }
              ></SubMenu>
            ),
          )}
      </Menu>
      <div className='sider-profile-controls'>
        {/* <Link
          to={`/admin/view-user/${currentuser && currentuser?.data?.id}`}
          className='sider-profile-controls-profile'
        >
          <UserOutlined />
        </Link> */}

        <LogoutOutlined onClick={handleSignOutSubmit} className='sider-profile-controls-logout' />
      </div>
    </Sider>
  );
};
export default NavMenu;
