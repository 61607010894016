import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ReactComponent as TruckIcon } from '../../truck-svgrepo-com.svg';
import { ReactComponent as ClientIcon } from '../../person-svgrepo-com.svg';
import { ReactComponent as TagIcon } from '../../tag-svgrepo-com.svg';
import { ReactComponent as BuidlingIcon } from '../../building-svgrepo-com.svg';
import { ReactComponent as LetterIcon } from '../../letter-svgrepo-com.svg';
import { ReactComponent as OrderIcon } from '../../browser-order-svgrepo-com.svg';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [user, fetchUser] = useAxios('', null, currentuser.data.token, 'get');
  const [orders, fetchOrders] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();

  useEffect(() => {
    if (id) {
      fetchUser(`${SERVER_URL}/users/${id}`, []);
      fetchOrders(`${SERVER_URL}/orders-by-user/${id}?limit=4`, []);
    }
    return () => { };
  }, [id, fetchUser, fetchOrders]);

  /*
    branches: [{
      name: { type: String },
      address: { type: String },
      zip: { type: String },
      city: { type: String },
      phone: { type: String },
    }],

  */

  const navigateOrder = (record) => {
    history.push(`/company/view-a-order/${record.id}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const columns = [
    {
      key: 'orderNumber',
      title: 'BROJ PORUDŽBINE',
      dataIndex: 'orderNumber',
    },
    {
      key: 'createdAt',
      title: 'DATUM',
      render: (text, record, index) => (
        <div>
          {new Date(record.createdAt).toLocaleDateString('sr-Latn-RS', dateOptions)}
        </div>
      )
    },
    {
      key: 'totalPrice',
      title: 'IZNOS',
      render: (text, record, index) => {
        const order = record.order;
        const total = (order.reduce((acc, curr) => acc + curr.price * curr.quantity * ((100 - curr.discount) / 100), 0));
        const decimalTotal = total.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return (
          <div>
            {decimalTotal} RSD
          </div>
        );
      },
    },
    {
      key: 'status',
      title: 'STATUS',
      dataIndex: 'status',
      render: (text, record, index) => (
        <div>
          {record.status === 'Processed' ? 'ODRAĐENA' : record.status}
        </div>
      )
    },
    {
      key: 'action',
      title: 'PORUDŽBINA',
      render: (text, record, index) => (
        <button style={{ textDecoration: 'underline' }} onClick={() => navigateOrder(record)}>
          Pogledaj
        </button>
      )
    }
  ];

  return (
    <>
      <div className='edit-panel' style={{ boxShadow: 'unset' }}>

        <div className='content content-full-height' style={{ padding: '0px 20px' }}>
          {id && user.isLoading && <LoadingOutlined className='loader' spin />}
          {id && !user.isLoading && user.isError && <h2 style={{ marginTop: '5rem' }}>Nešto nije u redu :(</h2>}
          {id && user && user.data && !user.isLoading && !user.isError && (
            <div className='flex-wrapper' style={{ display: 'flex' }}>
              <div style={{ width: '25%', minWidth: '290px', padding: '10px' }}>
                <div>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 700, display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <ClientIcon style={{ height: '25px', width: '25px', color: '#555' }} />
                    <span style={{ marginTop: '4px', fontSize: '14px' }}>
                      KONTAKT INFORMACIJE
                    </span>
                  </span>
                  <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px', fontSize: '14px' }}>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', backgroundColor: 'white', }} readOnly value={user.data.fullName} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', backgroundColor: 'white' }} readOnly value={user.data.phone1} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', backgroundColor: 'white' }} readOnly value={user.data.phone2} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', backgroundColor: 'white' }} readOnly value={user.data.email} disabled></input>
                  </div>
                </div>
                <div style={{ marginTop: '30px' }}>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 700, display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <BuidlingIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                    <span style={{ fontSize: '14px' }}>
                      PODACI O PRAVNOM LICU
                    </span>
                  </span>
                  <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px', fontSize: '14px' }}>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={user.data.legalData?.companyName} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={user.data.legalData?.address} disabled></input>
                    <div style={{ display: 'flex', width: '100%' }}>
                      <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '30%', marginRight: '10px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={user.data.legalData?.zip} disabled></input>
                      <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '70%', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={user.data.legalData?.city} disabled></input>
                    </div>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={`PIB: ${user.data.legalData?.PIB}`} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={`MB: ${user.data.legalData?.MB}`} disabled></input>
                    <input style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px', backgroundColor: 'white' }} readOnly value={user.data.legalData?.phone} disabled></input>
                  </div>
                </div>
              </div>
              <div style={{ width: '25%', minWidth: '290px', padding: '10px' }}>
                <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 700, display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <TruckIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                  <span style={{ fontSize: '14px' }}>
                    PODACI ZA DOSTAVU
                  </span>
                </span>
                <div style={{ paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px', fontSize: '14px' }}>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[0]?.name} disabled></input>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[0]?.address} disabled></input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '30%', marginRight: '10px', borderRadius: '2px' }} readOnly value={user.data.branches[0]?.zip} disabled></input>
                    <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '70%', borderRadius: '2px' }} readOnly value={user.data.branches[0]?.city} disabled></input>
                  </div>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[0]?.phone} disabled></input>
                </div>
                <div style={{ margin: '30px 0px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '250px', fontSize: '14px' }}>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[1]?.name} disabled></input>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[1]?.address} disabled></input>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '30%', marginRight: '10px', borderRadius: '2px' }} readOnly value={user.data.branches[1]?.zip} disabled></input>
                    <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', width: '70%', borderRadius: '2px' }} readOnly value={user.data.branches[1]?.city} disabled></input>
                  </div>
                  <input style={{ backgroundColor: 'white', margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 15px', height: '30px', borderRadius: '2px' }} readOnly value={user.data.branches[1]?.phone} disabled></input>
                </div>
              </div>
              <div className='rabat-wrapper' style={{ width: '50%', padding: '10px' }}>
                <div>
                  <span style={{ color: '#FE602C', fontWeight: 700 }}>
                    <TagIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px', fontSize: '14px' }} />
                    <>
                      MOJI RABATI I VALUTE PLAĆANJA
                    </>
                  </span>
                  <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column' }}>
                    {user.data.supplierDiscount && user.data.supplierDiscount.length > 0 && user.data.supplierDiscount.map((item) => (
                      <div style={{ display: 'flex', fontSize: '12px' }}>
                        <input className='rabatInput' style={{ margin: '4px 0', border: '1px solid #d4d4d4', borderRight: 'unset', padding: '1px 10px', height: '30px', borderRadius: '2px', width: '30%' }} readOnly value={item?.supplierId?.title?.en}></input>
                        <input className='rabatInput' style={{ backgroundColor: 'rgb(254, 96, 44)', color: '#fff', textAlign: 'center', margin: '4px 0', border: '1px solid #d4d4d4', borderRight: 'unset', padding: '1px 5px', height: '30px', borderRadius: '2px', width: '20%' }} readOnly value={`RABAT ${item.discount}%`}></input>
                        <input className='rabatInput' style={{ margin: '4px 0', border: '1px solid #d4d4d4', padding: '1px 10px', textAlign: 'center', height: '30px', borderRadius: '2px', color: 'rgb(254, 96, 44)', width: '30%' }} readOnly value={`VALUTA ${item.currency} DANA`}></input>
                      </div>
                    ))
                    }
                  </div>
                </div>
                {/* <button onClick={() => updateUser()}>xd</button> */}
                {/* <div>
                  <span style={{ color: '#FE602C', fontSize: '0.7rem', fontWeight: 600 }}>
                    <LetterIcon style={{ fill: '#555', height: '25px', width: '25px', marginRight: '5px' }} />
                    <>
                      NEWSLETTER
                    </>
                  </span>
                  <div style={{ marginBottom: '25px', paddingTop: '10px', display: 'flex', flexDirection: 'column', maxWidth: '90%', minWidth: '450px' }}>
                    <div>
                      <p style={{ marginBottom: '10px' }}>Prijavljeni ste na naš newsletter.</p>
                      <button style={{ textDecoration: 'underline' }}>Odjavi se</button>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

          )}
        </div>
      </div>
      <div style={{ height: 'auto', marginTop: '25px', padding: '30px 30px' }}>
        <div style={{ display: 'flex', padding: '10px 0px', marginBottom: '25px' }}>
          <OrderIcon style={{ fill: '#555', height: '20px', width: '20px', marginRight: '5px' }} />
          <span style={{ color: 'rgb(254, 96, 44)', fontWeight: '700', fontSize: '14px' }}>
            NEDAVNE PORUDŽBINE
          </span>
        </div>
        {orders && orders.data && orders.data.items && orders.data.items.length > 0 &&
          <div className='user-table'>
            <Table
              dataSource={orders.data.items}
              columns={columns}
              pagination={false}
              bordered={false}
            />
          </div>
        }
      </div>
    </>
  );
};

export default ViewUser;
