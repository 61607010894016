import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { LoadingOutlined } from '@ant-design/icons';
import { Table, Row, Col, Input } from 'antd';
import Axios from 'axios';
import OrderOneForm from '../../components/forms/OrderOneForm';

function ViewOneOrder(props) {
  const { id } = props.match.params;
  const currentuser = useContext(UserContext);
  const [order, setOrder] = useState({});

  const fetchOrder = async () => {
    try {
      const res = await Axios.get(`${SERVER_URL}/orders/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });
      setOrder(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (id) fetchOrder();
    return () => { };
  }, [id]);


  const isDataFetched = order && Object.keys(order).length > 0 && currentuser.language;

  return (
    <>
      {!isDataFetched && (
        <div style={{ textAlign: 'center' }}>
          <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />
        </div>
      )}
      {id && order && isDataFetched && (
        <OrderOneForm
          data={order}
          language={currentuser.language}
          token={currentuser.data.token}
        />
      )
      }
    </>
  );
}

export default ViewOneOrder;
