import React, { useContext, useEffect, useState } from 'react';
import { Table, notification, Spin } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import moment from 'moment';
import TopSearchBarOrders from './TopSearchBarOrders';
import { useHistory } from 'react-router-dom';

const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

const MyOrders = () => {
  const currentuser = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [working, setWorking] = useState(false);
  const [yearChecked, setYearChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [search, setSearch] = useState(null);

  const history = useHistory();

  const navigateOrder = (record) => {
    history.push(`/company/view-a-order/${record.id}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    try {
      let query = '';
      if (yearChecked) query += '&year=2021';
      if (allChecked) query += '&all=true';
      if (search) query += `&search=${search}`;

      const fetchOrders = async () => {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/orders-by-user/${currentuser.data.id}?${query}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res.data) {
          setOrders(res.data.items);
        }
        setWorking(false);
      };

      fetchOrders();
    } catch (error) {
      setWorking(false);
      console.log('Error on load orders', error);
      notification.error({
        message: 'Error on load orders.',
        placement: 'bottomRight',
      });
    }
    return () => { };
  }, [yearChecked, allChecked, search, currentuser.data.token]);

  const columns = [
    {
      key: 'orderNumber',
      title: 'BROJ PORUDŽBINE',
      dataIndex: 'orderNumber',
    },
    {
      key: 'invoiceNumber',
      title: 'BROJ FAKTURE',
      dataIndex: 'invoiceNumber',
    },
    {
      key: 'createdAt',
      title: 'DATUM',
      dataIndex: 'createdAt',
      render: (text, record) => moment(record.resolutionDate).format('DD.MM.YYYY'),
    },
    {
      key: 'createdAt',
      title: 'VREME',
      dataIndex: 'createdAt',
      render: (text, record) => {
        const date = new Date(record.createdAt)
        return (
          date.toLocaleTimeString('sr-Latn-RS', {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          })
        )
      },
    },
    {
      key: 'totalPrice',
      title: 'IZNOS',
      render: (text, record) => {
        const order = record.order[0];
        if (order && order.discount) {
          const totalPrice = record.totalPrice || 0;
          const discount = order.discount || 0;
          const discountedPrice = totalPrice - totalPrice * (discount / 100);
          const decimalTotal = discountedPrice.toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return `${decimalTotal} RSD`;
        } else if (record.totalPrice) {
          return `${record.totalPrice},00 RSD`;
        } else {
          return 'N/A';
        }
      },
    },

    {
      key: 'status',
      title: 'STATUS',
      dataIndex: 'status',
      render: (value, record) => (
        <div>{record.status === 'Processed' ? "ODRAĐENA" : record.status}</div>
      )
    },
  ];


  columns.push({
    title: 'PORUDŽBINA',
    render: (text, record, index) => {
      return (
        <div>
          <button
            onClick={() => navigateOrder(record)}
            style={{ border: '0', cursor: 'pointer' }}
          >
            POGLEDAJ
          </button>
        </div>
      );
    },
  });

  return (
    <div>
      <TopSearchBarOrders
        yearChecked={yearChecked}
        setYearChecked={setYearChecked}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        search={search}
        setSearch={setSearch}
      />
      <Spin spinning={working} tip='Ucitavanje...'>
        <div>
          <div className='user-table' style={{ padding: '0 60px' }}>
            <Table
              size='small'
              bordered
              dataSource={orders}
              columns={columns}
              rowKey='_id'
              // rowClassName={(record, index) => amount[index] !== 0 && 'ordered'}
              pagination={{
                defaultPageSize: 20,
                position: 'bottom',
                showSizeChanger: false,
                pageSizeOptions: ['10', '20', '50', '100'],
                hideOnSinglePage: true,
              }}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default MyOrders;