import React, { useState, useEffect } from 'react';
// import img from 'next/image';
// import dynamic from 'next/dynamic';
// import { useRouter } from 'next/router';
// const MailchimpSubscribe = dynamic(() => import('react-mailchimp-subscribe'));
// const CustomForm = dynamic(() => import('../components/forms/NewsletterForm'));

export const Footer = () => {
  // const [windowWidth, setWindowWidth] = useState();
  // const url = '';
  // const router = useRouter();

  // useEffect(() => {
  //   setWindowWidth(window.innerWidth);
  // }, []);

  return (
    <footer id='footerId' style={{ height: 'fitContent', width: '100%', position: 'relative', zIndex: 7 }}>
      <div className='container'>
        <div className='footer-wrapper'>
          <div className='column column-logo'>
            <div
              className='footer-logo-container'
            // style={router.route === '/category' && windowWidth < 576 ? { display: 'none' } : { display: 'block' }}
            >
              <img
                src='/images/himtexPrimaryWhite.svg'
                alt='logo_2'
                style={{ width: '73px', height: '23px', objectFit: 'contain' }}
                className='footer-logo'
                width={65}
                height={23}
              />
            </div>
            <nav>
              <ul className='nav-first-column-footer'>
                <li>
                  <a href='#!'>O nama</a>
                </li>
                <li>
                  <a href='/contact'>Kontakt</a>
                </li>
                <li>
                  <a href='/najnovije-vesti'>Novosti</a>
                </li>
                <li>
                  <a href='#!'>Loyalty</a>
                </li>
              </ul>
            </nav>
            <ul className='footer-social-media'>
              <li>
                <a href='https://www.facebook.com/HimtexShop' target='_blank' rel='noopener'>
                  <img src='/images/facebook.svg' width='20' height='22' alt='facebook' />
                </a>
              </li>
              <li>
                <a href='https://twitter.com/HimtexCompany' target='_blank' rel='noopener'>
                  <img src='/images/twitter.svg' width='20' height='22' alt='twitter' />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/himtexcompanyhogert/' target='_blank' rel='noopener'>
                  <img src='/images/instagram.svg' width='20' height='22' alt='instagram' />
                </a>
              </li>
            </ul>
          </div>
          <div className='column column-info'>
            <nav>
              <ul>
                <li>Informacije</li>
                <li>
                  <a href='/privacy-policy'>Politika privatnosti</a>
                </li>
                <li>
                  <a href='/general-terms'>Opšti uslovi</a>
                </li>
                <li>
                  <a href='/ordering-payments'>Naručivanje i plaćanje</a>
                </li>
                <li>
                  <a href='/cancellation-purchase'>Odustanak od kupovine</a>
                </li>
                <li>
                  <a href='/reclamations'>Reklamacije</a>
                </li>
                <li>
                  <a href='/cookies-policy'>Pravila oko "Kolačića"</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='column column-docs'>
            <nav>
              <ul>
                <li>Dokumenta</li>
                <li>
                  <a href='/docs/ID-obrazac.pdf' target='_blank'>
                    Obrazac ID
                  </a>
                </li>
                <li>
                  <a href='/docs/Obrazac-PDV.pdf' target='_blank'>
                    Obrazac PDV
                  </a>
                </li>
                <li>
                  <a href='/docs/Obrazac-reklamacije.pdf' target='_blank'>
                    Obrazac Reklamacije
                  </a>
                </li>
                <li>
                  <a href='/docs/Obrazac-reklamacije-vp.pdf' target='_blank'>
                    Obrazac Reklamacije VP
                  </a>
                </li>
                <li>
                  <a href='/docs/Ugovor_o_kuporodaji_na_daljinu.pdf' target='_blank'>
                    Ugovor o kupoprodaji na daljinu
                  </a>
                </li>
                <li>
                  <a href='/docs/Obrazac_za_jednostrani_raskid_ugovora.pdf' target='_blank'>
                    Jednostrani raskid ugovora
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='column column-contact'>
            <nav>
              <ul>
                <li>Kontakt</li>
                <li className='address-footer' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ marginRight: '15px', width: 'fit-content' }}>
                    <img src='/images/kontakt_office.svg' alt='kontakt office' width='15' height='19' />
                  </div>
                  <span>Ivana Antunovića 94</span>
                </li>
                <li>24000 Subotica</li>
                <li style={{ fontSize: '12px' }}>Radno vreme: Svakog dana, 0-24</li>
                <li className='phone-footer'>
                  <a
                    href='tel:+381 24 527 562'
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingRight: '15px',
                    }}
                  >
                    <div style={{ marginRight: '15px', width: 'fit-content' }}>
                      <img src='/images/kontakt.svg' alt='kontakt' width='18' height='18' />
                    </div>
                    <span>+381 24 527 562</span>
                  </a>
                </li>
                <li>
                  <a href='mailto: office@himtexcompany.com'>office@himtexcompany.com</a>
                </li>
                <li>
                  <a href='/stores'>Prodajna mesta</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='column column-newsletter'>
            <nav>
              <ul>
                <li className='newsletter-title'>Saznaj prvi!</li>
                <li className='mailing-list'>
                  Prijavite se na mejling listu sa promocijama, obaveštenjima i sniženjima
                </li>
                {/* <li className='newsletter'>
                  <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                      <CustomForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                      />
                    )}
                  />
                </li> */}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='horizontal-divider'></div>
      <div className='container footer-bottom'>
        <p className='footer-copyright'>
          <span style={{ color: '#fff', fontWeight: 'bold' }}>&copy; 2021 Himtex Company DOO,</span> Sva prava zadržana
        </p>
        <div className='cards-wrapper'>
          <a
            href='https://www.allsecure.rs/'
            target='_blank'
            rel='noopener'
            style={{ marginRight: '20px' }}
            className='all-secure-icon'
          >
            <img src='/images/allsecure.svg' width='120' height='32' alt='all secure' />
          </a>
          <a href='https://www.unicreditbank.rs/rs/' target='_blank' rel='noopener'>
            <img src='/images/ucbs.svg' width='120' height='16' alt='uniCredit bank' />
          </a>
          <div style={{ marginRight: '20px', marginLeft: '20px' }}>
            <img src='/images/visa-cof-white.svg' width='40' height='25' className='visa-icon' alt='visa' />
          </div>
          <div style={{ marginRight: '20px' }}>
            <img src='/images/master.svg' width='40' height='25' className='master-icon' alt='master card' />
          </div>
          <div style={{ marginRight: '20px' }}>
            <img src='/images/mc_idcheck.svg' width='25' height='25' alt='mc idcheck' />
          </div>
          <div>
            <img src='/images/visa_secure.svg' width='25' height='25' alt='visa secure' />
          </div>
        </div>
      </div>
    </footer>
  );
};
