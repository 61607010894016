import React from 'react';
import { Tabs } from 'antd';
import Reclamations from './Reclamations';
import Refunds from './Refunds';

const { TabPane } = Tabs;

const ReclamationsTabs = () => {
  return (
    <div>
      <Tabs defaultActiveKey='REKLAMACIJE' /* onChange={callback} */>
        <TabPane tab='REKLAMACIJE' key='REKLAMACIJE'>
          <Reclamations />
        </TabPane>

        <TabPane tab='POVRATI ROBE' key='POVRATI ROBE'>
          <Refunds />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ReclamationsTabs;
