import React from 'react';
import TopBarMenuItem from './TopBarMenuItem';
import { useContext } from 'react';
import { UserContext } from '../../App';
import TopBarUser from './TopBarUser';

const TopBarMenu = (props) => {
  const currentuser = useContext(UserContext);
  const adminRole = sessionStorage.getItem('adminRole');

  const topBarMenuItems = [
    {
      icon: 'ri-settings-3-line',
      label: 'MOJI PODACI',
      link: `/company/view-user/${currentuser && currentuser?.data?.id}`,
    },
    {
      icon: 'ri-list-check',
      label: 'MOJE PORUDŽBINE',
      link: `/company/orders/${currentuser && currentuser?.data?.id}`,
    },
    // {
    //   icon: 'ri-file-list-3-line',
    //   label: 'REKLAMACIJE I POVRATI',
    //   link: `/company/reclamations/${currentuser && currentuser?.data?.id}`,
    // },
    // {
    //   icon: 'ri-bar-chart-box-line',
    //   label: 'KATALOZI I CENOVNICI',
    //   link: `/company/catalogs/${currentuser && currentuser?.data?.id}`,
    // },
    // {
    //   icon: 'ri-currency-line',
    //   label: 'FINANSIJE',
    //   link: `/company`,
    // },
  ];

  return (
    <div className='top-bar-menu-container'>
      {topBarMenuItems.map((item, index) => {
        return (
          <TopBarMenuItem key={index} topBarMenuItemProps={item} topBar={props.topBar} setTopBar={props.setTopBar} />
        );
      })}
      {adminRole && (adminRole === 'admin' || adminRole === 'commercialist') && (
        <TopBarUser currentuser={currentuser} />
      )}
    </div>
  );
};

export default TopBarMenu;
