import React, { useState, useEffect, useContext } from 'react';
import { Button, notification, Spin } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import moment from 'moment';

const Catalogs = () => {
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [catalogs, setCatalogs] = useState([]);

  useEffect(() => {
    try {
      const fetchCatalogs = async () => {
        setWorking(true);
        const res = await Axios.get(`${SERVER_URL}/catalogs`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res.data) {
          setCatalogs(res.data.items);
        }
        setWorking(false);
      };

      fetchCatalogs();
    } catch (error) {
      setWorking(false);
      console.log('Error on load catalogs', error);
      notification.error({
        message: 'Error on load catalogs.',
        placement: 'bottomRight',
      });
    }
  }, [currentuser.data.token]);

  return (
    <div>
      <Spin spinning={working} tip='Ucitavanje...'>
        <div style={{ display: 'flex' }}>
          <div>
            <h1 style={{ marginRight: '500px' }}>CENOVNICI</h1>
            <hr style={{ maxWidth: '450px' }}></hr>
            <br></br>
            <div>
              {catalogs.length > 0 &&
                catalogs.map((item, index) => (
                  <>
                    <div style={{ display: 'flex', fontSize: '12px' }}>
                      <img src={item.image && item.image.url} alt='icon' style={{ marginRight: '20px' }}></img>
                      <p>
                        {item.title.en} -{' '}
                        <strong>
                          <span style={{ color: 'rgb(249, 94, 0)', marginRight: '20px' }}>
                            AŽURIRAN {moment(item.updatedAt).format('DD.MM.YYYY')}
                          </span>
                        </strong>
                      </p>
                      <Button type='primary' style={{ backgroundColor: 'rgb(249, 94, 0)', color: 'white' }}>
                        POGLEDAJ
                      </Button>
                    </div>
                    {/* {index > 2 && (
                      <div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                      </div>
                    )} */}
                  </>
                ))}
            </div>
          </div>

          <div>
            <h1>KATALOZI</h1>
            <hr style={{ width: '450px' }}></hr>
            <br></br>
            <div>
              {catalogs.length > 0 &&
                catalogs.map((item, index) => (
                  <>
                    <div style={{ display: 'flex', fontSize: '12px' }}>
                      <img src={item.image && item.image.url} alt='icon' style={{ marginRight: '20px' }}></img>
                      <p>
                        {item.title.en} -{' '}
                        <strong>
                          <span style={{ color: 'rgb(249, 94, 0)', marginRight: '20px' }}>
                            AŽURIRAN {moment(item.updatedAt).format('DD.MM.YYYY')}
                          </span>
                        </strong>
                      </p>
                      <Button type='primary' style={{ backgroundColor: 'rgb(249, 94, 0)', color: 'white' }}>
                        POGLEDAJ
                      </Button>
                    </div>
                    {/* {index > 2 && (
                      <div>
                        <br></br>
                        <hr></hr>
                        <br></br>
                      </div>
                    )} */}
                  </>
                ))}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default Catalogs;
