import React, { useContext, useState } from 'react';
import { Form, Input, Button, Modal, message, Row, Col } from 'antd';
import { UserContext } from '../App';

import Axios from 'axios';
import { SERVER_URL } from '../config';

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundColor: '#ffff',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const companyLogoStyle = {
  maxWidth: '150px',
  marginBottom: '100px',
};

const Login = () => {
  const user = useContext(UserContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [showPartnerForm, setShowPartnerForm] = useState(false);
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  const handleShowPasswordReset = () => {
    setShowPasswordReset(true);
    form.setFields([{ name: 'email', errors: [] }]);
  };


  const handleBackToLogin = () => {
    setShowPasswordReset(false);
    form.setFields([{ name: 'email', errors: [] }]);
  };

  const onFinish = (values) => {
    user.handleLogin(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      form.resetFields();

      const response = await Axios.post(
        `${SERVER_URL}/forgot-password`,
        { email: values.email },
        { withCredentials: false }
      );

      if (response.status === 200) {
        message.success(response.data.message, 3);
      }
    } catch (err) {
      console.log('\n Reset error = ', err.response.data.message);
      message.warning(err.response.data.message, 3);
    }
    setIsModalOpen(false);
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ ...backgroundStyle, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Row gutter={[16, 16]}>
        <Col className='loginFirstCol' xs={24} sm={24} md={12} lg={12} xl={10}>
          <div className='login-content'>
            <img style={companyLogoStyle} className='login-logo' src='/himtex-logo-gray.svg' alt='Himtex Logo' />
            {!showPasswordReset ? (
              <div className='firstStepLogin'>
                <h2>PRIJAVA NA <span style={{ color: '#F95E00' }}>B2B</span> PORTAL ZA PARTNERE</h2>
                <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                  <Form.Item name='email' rules={[{ required: true, message: 'Molim Vas da unesete vaš email!' }]}>
                    <Input placeholder='e-mail' className='orangeInput' />
                  </Form.Item>


                  <Form.Item name='password' rules={[{ required: true, message: 'Molim Vas da unesete vaš password!' }]}>
                    <Input.Password placeholder='lozinka' className='orangeInput' />
                  </Form.Item>

                  <Form.Item
                    {...tailLayout}
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Button type='primary' style={{ backgroundColor: '#f95e00', borderRadius: '5px', color: '#fff', fontSize: '16px' }} htmlType='submit'>
                      PRIJAVA
                    </Button>
                    <span
                      className='forgot-password-span'
                      type='link'
                      onClick={() => handleShowPasswordReset()}
                    >
                      Zaboravljena lozinka?
                    </span>
                  </Form.Item>

                </Form>
                {/* <p>ZELITE POSTATI NAS PARTNER? Ispunite obrazac.</p>*/}
                {showPartnerForm && (
                  <Form>
                    {/* Add form fields for partner registration */}
                    {/* For example: Name, Surname, Phone, Address, etc. */}
                    {/* Form.Item for each field you want to add */}
                  </Form>
                )}
              </div>
            ) : (
              <div className='secondStepLogin'>
                <h2>ZABORAVLJENA LOZINKA</h2>
                <Form
                  key={showPasswordReset ? 'secondStep' : 'firstStep'}
                  form={form}
                  layout='vertical'
                  requiredMark={false}
                  onFinish={handleOk}
                >
                  <Form.Item
                    name='email'
                    rules={[{ required: true, message: 'Molimo, unesite Vašu email adresu!' }]}
                  >
                    <div>
                      <Input type='email' placeholder='e-mail' className='orangeInput' />
                      <p className='instructionReset'>Instrukcije za resetovanje lozinke će biti prosleđene na Vaš email.</p>
                    </div>
                  </Form.Item>


                  <div style={{ textAlign: 'center' }}>
                    <Button
                      type='primary'
                      style={{ backgroundColor: '#f95e00', borderRadius: '0', color: '#fff', marginBottom: '20px' }}
                      htmlType='submit'
                    >
                      POŠALJI ZAHTEV ZA NOVU LOZINKU
                    </Button>
                    <br />
                    <span
                      className='forgot-password-span'
                      type='link'
                      onClick={() => handleBackToLogin()}
                      style={{ marginLeft: '0px', marginTop: '20px' }}
                    >
                      Nazad na prethodnu stranu
                    </span>
                  </div>
                </Form>

              </div>
            )}
          </div>
        </Col>
        <Col className='loginSecCol' xs={24} sm={24} md={12} lg={12} xl={14}>
          <div className='login-image'>
            <img className='login-image-img' src='/B2B-login.webp' alt='Himtex Login' />
          </div>
        </Col>
      </Row>
      <Modal
        centered
        mask={true}
        className="login-modal"
        title={(<span style={{ fontSize: '20px', fontStyle: 'bold' }}>Zaboravili ste lozinku?</span>)}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={<span style={{ color: '#f95e00' }}>Pošalji zahtev</span>}
        cancelText={<span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Otkaži</span>}
        okButtonProps={{
          style: {
            display: 'inline-block',
            width: 'calc(50% - 4px)',
            borderLeft: '1px solid #e8e8e8',
            borderBottom: 'none',
            background: 'transparent',
            padding: 0,
            borderRadius: 0
          },
        }}
        cancelButtonProps={{
          style: {
            display: 'inline-block',
            width: 'calc(50% - 4px)',
            border: 'none',
            background: 'transparent',
            padding: 0,
          },
        }}
      >
        <Form form={form} layout='vertical' requiredMark={false}>
          <Form.Item
            name='email'
            rules={[{ required: true, message: 'Molimo, unesite Vašu email adresu!' }]}
            label={(
              <>
                Molimo Vas unesite email adresu na koju ćemo proslediti informacije o resetovanju lozinke.
                <br />
                <br />
                Unesite Vašu email adresu
              </>
            )}
          >
            <Input type='email' />
          </Form.Item>
        </Form>
      </Modal>
    </div >
  );
};

export default Login;
