import React from 'react';

const TopBarUser = ({ currentuser }) => {
  const firstName = currentuser?.data?.firstName;
  const lastName = currentuser?.data?.lastName;

  return (
    <div className='top-bar-menu-user'>
      <p style={{ display: 'flex', alignItems: 'center' }}>
        <i className='ri-user-fill ri-md'></i>
        {currentuser?.data?.role === 'wholesale user' ? currentuser?.data?.companyName : firstName + ' ' + lastName}
      </p>
    </div>
  );
};

export default TopBarUser;
