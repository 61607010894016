import React, { useContext, useEffect, useState } from 'react';
import { Table, notification } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import moment from 'moment';

const Refunds = () => {
  const currentuser = useContext(UserContext);
  const [data, setData] = useState([]);
  const [details, setDetails] = useState();

  useEffect(() => {
    try {
      const fetchReclamations = async () => {
        const res = await Axios.get(`${SERVER_URL}/refunds-by-user/${currentuser.data.id}`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (res.data) {
          setData(res.data);
        }
      };

      fetchReclamations();
    } catch (error) {
      console.log('Error on load refunds', error);
      notification.error({
        message: 'Error on load refunds.',
        placement: 'bottomRight',
      });
    }
  }, [currentuser.data.token]);

  const columns = [
    {
      key: 'refundNumber',
      title: 'BROJ POVRATA',
      dataIndex: 'refundNumber',
      render: (text, record) =>
        details && details._id === record._id ? (
          <p style={{ color: 'rgb(249, 94, 0)' }}>
            <strong>{text}</strong>
          </p>
        ) : (
          <p>{text}</p>
        ),
      // ...getColumnSearchProps('code'),
    },
    {
      key: 'createdAt',
      title: 'DATUM',
      dataIndex: 'createdAt',
      render: (text, record) => {
        return details && details._id === record._id ? (
          <p style={{ color: 'rgb(249, 94, 0)' }}>
            <strong>{moment(record.createdAt).format('DD.MM.YYYY')}</strong>
          </p>
        ) : (
          <p>{moment(record.createdAt).format('DD.MM.YYYY')}</p>
        );
      },
      // ...getColumnSearchProps('code'),
    },
    {
      key: 'status',
      title: 'STATUS',
      dataIndex: 'status',
      render: (text, record) =>
        details && details._id === record._id ? (
          <p style={{ color: 'rgb(249, 94, 0)' }}>
            <strong>{text}</strong>
          </p>
        ) : (
          <p>{text}</p>
        ),
      // ...getColumnSearchProps('code'),
    },
  ];

  columns.push({
    title: 'AKCIJA',
    render: (text, record, index) => {
      return details && details._id === record._id ? (
        <div>
          <p style={{ border: '0', color: 'rgb(249, 94, 0)', cursor: 'pointer' }} onClick={() => setDetails(record)}>
            <strong>POGLEDAJ</strong>
          </p>
        </div>
      ) : (
        <div>
          <p style={{ border: '0', color: 'black', cursor: 'pointer' }} onClick={() => setDetails(record)}>
            POGLEDAJ
          </p>
        </div>
      );
    },
  });

  const columnsDetails = [
    {
      key: 'code',
      title: 'ŠIFRA ARTIKLA',
      dataIndex: 'code',
      // ...getColumnSearchProps('code'),
    },
    {
      key: 'productName',
      title: 'NAZIV ARTIKLA',
      dataIndex: 'productName',
      // ...getColumnSearchProps('code'),
    },
    {
      key: 'refundReason',
      title: 'RAZLOG POVRATA',
      dataIndex: 'refundReason',
      // ...getColumnSearchProps('code'),
    },
    {
      key: 'refundResolutionMade',
      title: 'RAZREŠENJE',
      dataIndex: 'refundResolutionMade',
      render: (text, record) => <p style={{ color: 'rgb(249, 94, 0)' }}>{text}</p>,
      // ...getColumnSearchProps('code'),
    },
  ];

  return (
    <div>
      <div style={{ maxWidth: '600px' }}>
        <Table
          size='small'
          bordered
          dataSource={data}
          columns={columns}
          rowKey='_id'
          // rowClassName={(record, index) => amount[index] !== 0 && 'ordered'}
          pagination={{
            defaultPageSize: 20,
            position: 'bottom',
            showSizeChanger: false,
            pageSizeOptions: ['10', '20', '50', '100'],
            hideOnSinglePage: true,
          }}
        />
      </div>

      {details && (
        <div style={{ marginTop: '200px' }}>
          <div style={{ display: 'flex' }}>
            <h4>
              <strong>&#9776; POVRAT BR. {details.refundNumber}</strong>
            </h4>
            <p style={{ marginLeft: '300px', fontSize: '12px' }}>{moment(details.createdAt).format('DD.MM.YYYY')}</p>
          </div>

          <hr style={{ width: '520px' }}></hr>
          <br></br>

          <div style={{ fontSize: '13px' }}>
            <p>
              ZASNOVANO NA:{' '}
              <span style={{ color: 'rgb(249, 94, 0)' }}>
                <strong>
                  PORUDŽBINA {details.orderId && details.orderId.orderNumber ? details.orderId.orderNumber : 'N/A'}
                </strong>
              </span>
            </p>
            <p>
              STATUS:{' '}
              <span style={{ color: 'rgb(249, 94, 0)' }}>
                <strong>{details.status}</strong>
              </span>
            </p>
            <p>BROJ BANKOVNOG RAČUNA: </p>
          </div>

          <br></br>

          <div>
            <Table
              size='small'
              bordered
              dataSource={details.refund}
              columns={columnsDetails}
              rowKey='_id'
              // rowClassName={(record, index) => amount[index] !== 0 && 'ordered'}
              pagination={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Refunds;
