import React, { useState } from 'react';
import { Form, Input, Button, Card, message } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../config';
import {Link, Redirect} from 'react-router-dom';
import Login from "./Login";

const backgroundStyle = {
    width: '100vw',
    height: '100vh',
    backgroundColor: '#666666',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
};
const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 24 },
};
const tailLayout = {
    wrapperCol: { span: 24 },
};
const loginCardStyle = {
    borderRadius: '20px',
    background: 'rgba(255, 255, 255, 0.8)',
};
const companyLogoStyle = {
    maxWidth: '250px',
    marginBottom: '50px',
};
const ResetPassword = () => {
    const [password, setPassword] = useState('');

    const url =  window.location.href;
    const splitUrl = url.split('/');
    const uuid = splitUrl[splitUrl.length - 2];
    const mobile = splitUrl[splitUrl.length - 1];

    const onFinish = async () => {
        const urlParts = window.location.href.split('/');
        let token;
        if (mobile !== 'mobile') {
            token = urlParts[urlParts.length - 1]
        } else {
            token = uuid;
        }
        try {
            const resetPassword = await Axios.post(
                `${SERVER_URL}/password-reset/${token}`,
                { password },
                { withCredentials: false },
            );
            if (resetPassword.status === 200) {
                return message.success('Lozinka je uspešno promenjena.', 3);
            }
        } catch (err) {
            return message.warning(err.response.data.message, 3);
        }
    };
    return (
        <div className='log' style={backgroundStyle}>
            <div className='card-wrapper' >
                <Card className='login-header' bordered={false} style={loginCardStyle} title='Unesite novu lozinku'>
                    <img style={companyLogoStyle} className='login-logo' src='/himtex-logo.svg' alt='Himtex Logo' />
                    <Form {...layout} name='basic' onFinish={onFinish}>
                        <Form.Item name='password' rules={[{ required: true, message: 'Molimo Vas, unesite novu lozinku!' }]}>
                            <Input onChange={(e) => setPassword(e.target.value)} placeholder='Nova lozinka' type='password' />
                        </Form.Item>
                        <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                            <Button type='primary' style={{ width: '100%', backgroundColor: '#f95e00' }} htmlType='submit'>
                                Pošalji
                            </Button>
                        </Form.Item>
                        {mobile !== 'mobile' &&
                        <Form.Item {...tailLayout} style={{ paddingTop: '30px' }}>
                            <a href='/login' className='link-forgot-password'>
                                Uloguj se
                            </a>
                        </Form.Item>
                        }
                    </Form>
                </Card>
            </div>
        </div>
    );
};
export default ResetPassword;