import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { BranchTable } from '../../components/tables';
import { Button, Input, notification } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function ViewBranches() {
  const user = useContext(UserContext);
  const [branches, setBranches] = useState();
  const [search, setSearch] = useState('');

  const getCompanyBranches = async () => {
    try {
      const call = await axios.post(`${SERVER_URL}/company/branches`, { companies: user.data.companies }, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${user.data.token}` }
      });
      setBranches(call.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (search.length > 0) {
      const data = branches.filter(item => item.branchName.toLowerCase().indexOf(search.toLowerCase()) >= 0);
      setBranches(data);
    } else {
      getCompanyBranches();
    }
  }, [search]);

  const deleteDataHandler = async (id) => {
    try {
      await axios.delete(`${SERVER_URL}/branches/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${user.data.token}` } });
      notification.success({
        message: 'Ogranak je izbrisan.',
        placement: 'bottomRight',
      });
      setBranches(branches.filter(branch => branch._id !== id));
    } catch (err) {
      notification.error({
        message: 'Problem sa brisanjem. Molim Vas pokušajte kasnije.',
        placement: 'bottomRight',
      });
    }
  };

  const columnKeys = ['address', 'city', 'branchName', 'createdAt', 'updatedAt'];
  const data = branches?.map(branch => {
    branch.createdAt = new Date(branch.createdAt).toUTCString().substring(5, 22);
    branch.updatedAt = new Date(branch.updatedAt).toUTCString().substring(5, 22);
    return branch;
  });

  return (
    <div className='table'>
      <div className='actions-block'>
        <Input
          bordered={false}
          placeholder='Pretraga'
          prefix={<SearchOutlined />}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Link to='/company/new-branch'>
          <button className='b2b-primary'>
            Dodaj ogranak
          </button>

        </Link>
      </div>
      {branches &&
        <BranchTable
          data={data}
          columnKeys={columnKeys}
          editPath='/company/edit-branch/'
          deleteBranch={deleteDataHandler}
        />
      }
    </div>
  );
}
