import React, { useState } from 'react';
import { Input, Checkbox } from 'antd';

const TopSearchBarOrders = ({ yearChecked, setYearChecked, allChecked, setAllChecked, search, setSearch }) => {
  return (
    <div className='top-search-bar-container'>
      <div className='top-bar-menu-item-box orange'>

        <Input className='search-input'
          placeholder='UPIŠITE BROJ FAKTURE, PORUDŽBINE, ŠIFRU ARTIKLA...'
          style={{ width: '500px', paddingLeft: '40px' }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <i
          className='ri-search-line'
          style={{ color: '#9f9f9f', position: 'relative', top: 0, right: '30px', zIndex: '7' }}
        ></i>
      </div>
      <div className='top-bar-menu-item-box orange'>
        <Checkbox checked={allChecked} onChange={() => setAllChecked(!allChecked)} />
        SVE
      </div>
      <div className='top-bar-menu-item-box orange'>
        <Checkbox checked={yearChecked} onChange={() => setYearChecked(!yearChecked)} />
        2021
      </div>
    </div>
  );
};

export default TopSearchBarOrders;
